import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { MODES } from '../../config/themeContext'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type DarkModeButtonProps = {
  theme: string
  className: any
  setTheme: (newTheme: string) => void
}

const DarkModeButton = ({ className, theme, setTheme }: DarkModeButtonProps) => {
  const [localTheme, setLocalTheme] = useState(theme)

  const onClick = () => {
    const newTheme = localTheme === MODES.DARK ? MODES.LIGHT : MODES.DARK

    setLocalTheme(newTheme) // change local
    setTheme(newTheme) // change global (global theme is not rerendered here)
  }

  const iconClass = classnames('text-sm text-gray-700 text-white')

  return (
    <button
      className={classnames(
        'w-16 h-8 rounded-full bg-white flex items-center transition duration-300 ease-in-out focus:outline-none shadow',
        className,
      )}
      onClick={onClick}
    >
      <div
        className={classnames(
          'w-8 h-8 relative rounded-full transition duration-500 p-1 text-white flex items-center justify-center',
          {
            'bg-yellow-500': localTheme === MODES.LIGHT,
            'bg-gray-700 translate-x-full': localTheme === MODES.DARK,
          },
        )}
      >
        {localTheme === MODES.LIGHT ? (
          <FontAwesomeIcon className={iconClass} icon={['fal', 'sun']} />
        ) : (
          <FontAwesomeIcon className={iconClass} icon={['fal', 'moon']} />
        )}
      </div>
    </button>
  )
}

DarkModeButton.propTypes = {
  theme: PropTypes.string,
  className: PropTypes.any,
  setTheme: PropTypes.func,
}

export default DarkModeButton
