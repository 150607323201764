import React from 'react'
import classnames from 'classnames'
import AnswerBox from './AnswerBox'

const AnswerBoxGroup = ({
  answers,
  activeAnswers,
  wrongAnswers,
  correctAnswers,
  onClick,
  isResponded,
  isCorrect,
  showCorrectAnswer,
  classNames,
}) => {
  const isActivated = (answer) => {
    return isResponded === false && activeAnswers.indexOf(answer.id) !== -1
  }

  const isSuccess = (answer) => {
    if (isResponded === false) {
      return false
    }

    const isCorrectAnswer = correctAnswers.indexOf(answer.id) !== -1

    return isCorrectAnswer && (isCorrect || showCorrectAnswer)
  }

  const isError = (answer) => {
    if (isResponded === false) {
      return false
    }

    const isWrongAnswer = wrongAnswers.indexOf(answer.id) !== -1

    if (isWrongAnswer) {
      return true
    }

    // special case when answer is correct but wrong
    // when treating it within the group of answers (multiple answers)

    return isCorrect === false && activeAnswers.indexOf(answer.id) !== -1
  }

  return (
    <div className={classnames('flex flex-col sm:flex-row justify-around', classNames)}>
      {answers.map((answer) => (
        <AnswerBox
          key={`answer-${answer.id}`}
          disabled={isResponded}
          isActive={isActivated(answer)}
          isError={isError(answer)}
          isSuccess={isSuccess(answer)}
          onClick={(e) => onClick(answer.id, e)}
          answer={answer}
        />
      ))}
    </div>
  )
}

export default AnswerBoxGroup
