import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type SwitchButtonProps = {
  setValue: (val: boolean) => void
  className: any
  value: boolean
}

const SwitchButton = ({ className, value, setValue }: SwitchButtonProps) => {
  const [localValue, setLocalValue] = useState(value)

  const onClick = () => {
    const nextValue = !localValue
    setLocalValue(nextValue)
    setValue(nextValue)
  }

  const iconClass = classnames('text-2xl', {
    'text-gray-100': localValue === false,
    'text-gray-700': localValue === true,
  })

  return (
    <button
      className={classnames(
        'w-16 h-8 rounded-full bg-white flex items-center transition duration-300 ease-in-out focus:outline-none shadow',
        className,
      )}
      onClick={onClick}
    >
      <div
        className={classnames(
          'w-8 h-8 relative rounded-full transition duration-500 p-1 text-white flex items-center justify-center',
          {
            '': localValue === false,
            'translate-x-full': localValue === true,
          },
        )}
      >
        {localValue === true ? (
          <FontAwesomeIcon className={iconClass} icon={['fas', 'circle']} />
        ) : (
          <FontAwesomeIcon className={iconClass} icon={['fal', 'circle']} />
        )}
      </div>
    </button>
  )
}

SwitchButton.propTypes = {
  setValue: PropTypes.func,
  className: PropTypes.any,
  value: PropTypes.bool,
}

export default SwitchButton
