import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AudioPlayer = ({ onClick, isPlaying, hidden }) => {
  const iconClassName = classnames('text-2xl', {
    'text-gray-200 dark:text-gray-600': isPlaying === false,
    'text-gray-400 dark:text-gray-400': isPlaying,
  })

  return (
    <button
      className={classnames('flex flex-col items-center justify-center focus:outline-none', {
        'absolute left-0 top-0 bottom-0 -ml-12': true,
        flex: hidden === false,
        hidden: hidden === true,
      })}
      onClick={onClick}
    >
      {isPlaying ? (
        <FontAwesomeIcon className={iconClassName} icon={['fal', 'volume-up']} />
      ) : (
        <FontAwesomeIcon className={iconClassName} icon={['fal', 'volume-off']} />
      )}
    </button>
  )
}

AudioPlayer.propTypes = {
  onClick: PropTypes.func,
  isPlaying: PropTypes.bool,
  hidden: PropTypes.bool,
}

export default AudioPlayer
