import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import HtmlContent from '../HtmlContent'

const AnswerBox = ({ answer, isActive, isError, isSuccess, isWarning, onClick, disabled }) => {
  const clickFunction = (e) => (disabled !== true ? onClick(answer.id, e) : null)

  return (
    <div
      onClick={clickFunction}
      onKeyDown={clickFunction}
      role="presentation"
      className={classnames('rounded-xl p-3 py-5 w-full border-2 select-none m-1', {
        'dark:border-gray-800': !isActive && !isSuccess && !isError && !isWarning,
        'border-blue-400 bg-blue-200 dark:bg-blue-600 dark:border-blue-800':
          isActive && !isSuccess && !isError && !isWarning,
        'border-lime-400 bg-lime-200 dark:bg-lime-600 dark:border-lime-800': isSuccess,
        'border-red-400 bg-red-200 dark:bg-red-600 dark:border-red-800': isError,
        'border-orange-400 bg-orange-200 dark:bg-orange-600 dark:border-orange-800': isWarning,
        'cursor-pointer': disabled !== true,
      })}
    >
      <div className={'flex flex-col items-center justify-center'}>
        <HtmlContent id={answer.id} katexDisplayMode={false} html={answer.content} />
      </div>
    </div>
  )
}

AnswerBox.propTypes = {
  answer: PropTypes.object,
  isActive: PropTypes.bool,
  isError: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isWarning: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

export default AnswerBox
