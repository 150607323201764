import { useState } from 'react'
import AudioMapList from '../utils/audio'
import useEffectOnce from '../../hooks/useEffectOnce'
import useIsHowlerUnlock from './useHowlerUnlock'

const useAudio = (audioMapID, audioID) => {
  const audioMap = AudioMapList.getMap(audioMapID)
  const audio = audioMap.getAudio(audioID)

  const [isPlaying, setIsPlaying] = useState(false)
  const [isPlayed, setIsPlayed] = useState(audio.isPlayed)

  audio.isPlayed = isPlayed // sync global isPlayed flag

  const howl = audio.getSound()

  const [isLoaded, setIsLoaded] = useState(howl.state() === 'loaded')

  const isHowlerUnlocked = useIsHowlerUnlock()

  const initListeners = () => {
    howl.on('play', () => {
      stopAll(audioID)
      setIsPlaying(true)
      setIsPlayed(true)
    })

    howl.on('end', () => {
      setIsPlaying(false)
      playNext(audioID)
    })

    howl.on('stop', () => {
      setIsPlaying(false)
    })

    howl.on('pause', () => {
      setIsPlaying(false)
    })

    howl.once('load', () => {
      setIsLoaded(true)
    })

    return () => howl.off()
  }

  useEffectOnce(initListeners)

  const play = () => howl.play()
  const stop = () => howl.stop()

  const stopAll = (exceptID) => audioMap.stopAll(exceptID)

  const playNext = (audioID) => {
    const nextAudio = audioMap.getNextAudio(audioID)

    if (!nextAudio) {
      return // if the audio is last - do nothing
    }

    // instead of automatically playing observe if the audio element is in view

    const observer = new IntersectionObserver(
      (entries, obs) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Stop observing
            obs.unobserve(nextAudio.getElement())

            // play next
            nextAudio.getSound().play()
          }
        })
      },
      {
        rootMargin: '0px 0px -30% 0px',
        threshold: 0.5,
      },
    )

    observer.observe(nextAudio.getElement())
  }

  return {
    play,
    stop,
    isPlaying,
    isLoaded,
    isHowlerUnlocked,
    isPlayed,
  }
}

export default useAudio
