import React, { useEffect } from 'react'
import classnames from 'classnames'
import AudioPlayer from './AudioPlayer'
import useAudio from '../../hooks/useAudio'
import useCallbackOnce from '../../../hooks/useCallbackOnce'

const AudibleElement = ({ html, audioID, audioMapID, autoplay }) => {
  const { play, stop, isPlaying, isLoaded, isHowlerUnlocked, isPlayed } = useAudio(
    audioMapID,
    audioID,
  )

  const autoplayCallback = useCallbackOnce(play) // prevent play change (double triggered)

  useEffect(() => {
    // auto play
    if (autoplay === true && isLoaded === true && isHowlerUnlocked === true && isPlayed === false) {
      autoplayCallback()
    }
  }, [autoplay, isLoaded, isHowlerUnlocked, autoplayCallback, isPlayed])

  const onClick = () => (isPlaying ? stop() : play())

  return (
    <div
      className={classnames('relative transition delay-100 duration-300 ease-linear', {
        'scale-105': isPlaying,
      })}
    >
      <AudioPlayer hidden={isLoaded === false} onClick={onClick} isPlaying={isPlaying} />

      <div dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  )
}

export default AudibleElement
