import { audioUrl } from '../../apis'
import { Howl, Howler } from 'howler'

/**
 * useSound creates many instances of Howls..
 *
 * @see https://github.com/joshwcomeau/use-sound/issues/11
 * @see https://github.com/joshwcomeau/use-sound/issues/24
 *
 * The idea is to create a map of howls
 */
class Audio {
  constructor(id, options = {}, el) {
    this.id = id
    this.url = audioUrl(id)
    this.sound = new Howl({ src: [this.url], ...options })
    this.isPlayed = false // will prevent autoplaying already played sounds
    this.element = el // used with intersection observer
  }

  getSound() {
    return this.sound
  }

  getElement() {
    return this.element
  }
}

/**
 * Contains audios per screen
 */
class AudioMap {
  constructor(id) {
    this.id = id
    this.audios = []
  }

  addAudio(id, options, el) {
    const findAudio = this.getAudio(id)

    if (findAudio) {
      return findAudio
    }

    const audio = new Audio(id, options, el)

    this.audios.push(audio)

    return audio
  }

  getAudio(id) {
    return this.audios.find((el) => el.id === id)
  }

  stopAll(exceptId) {
    this.audios.filter((el) => el.id !== exceptId).map((el) => el.getSound().stop())
  }

  getAudioIndex(id) {
    return this.audios.map((e) => e.id).indexOf(id)
  }

  getNextAudio(id) {
    const currentIndex = this.getAudioIndex(id)

    return this.audios[currentIndex + 1]
  }

  getPreviousAudio(id) {
    const currentIndex = this.getAudioIndex(id)

    return this.audios[currentIndex - 1]
  }
}

/**
 * Contains all the audio maps for the APP
 */
class AudioMapList {
  constructor() {
    this.maps = []
  }

  addMap(id) {
    this.maps[id] = new AudioMap(id)

    return this.maps[id]
  }

  getMap(id) {
    return this.maps[id] ? this.maps[id] : this.addMap(id)
  }

  stopAllSounds() {
    Howler.stop()
  }
}

const AudioMapListInitiated = new AudioMapList()

export default AudioMapListInitiated
