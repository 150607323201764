/**
 * Be careful if modifies original array
 *
 * https://stackoverflow.com/questions/6274339/how-can-i-shuffle-an-array
 * @param a
 * @returns {*}
 */
const shuffle = (a) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

/**
 * https://stackoverflow.com/questions/14446511/most-efficient-method-to-groupby-on-an-array-of-objects
 *
 * @param xs
 * @param key
 * @returns {*}
 */
const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

/**
 * https://stackoverflow.com/questions/5915096/get-a-random-item-from-a-javascript-array
 *
 * @param arr
 * @returns {*}
 */
const getRandomElement = (arr) => {
  return arr[Math.floor(Math.random() * arr.length)]
}

/**
 *
 * @param array
 * @returns {*}
 */
const clone = (array) => {
  return array.slice(0)
}

export { shuffle, groupBy, clone, getRandomElement }
