import confetti from 'canvas-confetti'

class ConfettiConstructor {
  constructor() {
    this.confetti = null
  }

  reset() {
    if (this.confetti === true) {
      confetti.reset()
      this.confetti = null
    }
  }

  questionCannon(el) {
    const [x, y] = this.getElementOrigin(el)

    confetti({
      particleCount: 100,
      spread: 70,
      startVelocity: 40,
      origin: {
        y: y,
        x: x,
      },
      ticks: 100,
    })

    this.confetti = true
  }

  resultsFireworks(seconds = 1) {
    var duration = seconds * 1000
    var animationEnd = Date.now() + duration
    var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 }

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min
    }

    var interval = setInterval(function () {
      var timeLeft = animationEnd - Date.now()

      if (timeLeft <= 0) {
        return clearInterval(interval)
      }

      var particleCount = 50 * (timeLeft / duration)
      // since particles fall down, start a bit higher than random
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        }),
      )
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        }),
      )
    }, 250)

    this.confetti = true
  }

  getElementOrigin(element) {
    const { top, height, left, width } = element.getBoundingClientRect()
    const x = (left + width / 2) / window.innerWidth
    const y = (top + height / 2) / window.innerHeight

    return [x, y]
  }
}

const Confetti = new ConfettiConstructor()

export default Confetti
