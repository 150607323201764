import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import api from '../../apis'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import QuestionAppBody from './Body'
import { useAllShuffled } from '../state/useAnswers'
import { shuffleAnswers } from '../hooks/useQuestionAnswers'

const QuestionApp = ({ questionId, classNames, showDarkModeSwitch }) => {
  const [pageData, setPageData] = useState(false)
  const [countCorrect, setCountCorrect] = useState(0)

  const [shuffledAnswers, setShuffledAnswers] = useAllShuffled()

  const reloadQuestion = useCallback(
    (level = null) => {
      setPageData(false)
      setShuffledAnswers({})

      api.question
        .getGeneratedQuestion(questionId, level)
        .then((response) => {
          setPageData(response.data)
          setShuffledAnswers(shuffleAnswers(response.data.answers))
        })
        .catch((error) => {
          if (error.response.status === 404) {
            setPageData(error.response.data.message)
            return Promise.resolve(error)
          }
        })
    },
    [questionId, setShuffledAnswers],
  )

  useEffect(() => {
    reloadQuestion()
  }, [questionId, reloadQuestion])

  if (pageData === false || Object.keys(shuffledAnswers).length === 0) {
    return (
      <div className={classnames('text-center text-black dark:text-white', classNames)}>
        <FontAwesomeIcon className={'text-5xl'} spin={true} icon={['fad', 'spinner-third']} />
      </div>
    )
  }

  if (typeof pageData === 'string') {
    return (
      <div className={classnames('text-center text-black dark:text-white', classNames)}>
        {pageData}
      </div>
    )
  }

  return (
    <QuestionAppBody
      {...pageData}
      reloadQuestion={reloadQuestion}
      countCorrectAnswers={countCorrect}
      showDarkModeSwitch={showDarkModeSwitch}
      isCorrectCallback={() => setCountCorrect(countCorrect + 1)} // count correct answers in a row
      isWrongCallback={() => setCountCorrect(0)} // reset if answer is wrong
      classNames={classNames}
    />
  )
}

QuestionApp.propTypes = {
  questionId: PropTypes.string.isRequired,
  classNames: PropTypes.any,
  showDarkModeSwitch: PropTypes.bool.isRequired,
}

export default QuestionApp
