import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CircleButton from '../../../components/Button/CircleButton'
import classnames from 'classnames'
import { renderBasicModal } from '../../../components/Modal/fabric'
import Settings from '../Settings'
import { ThemeContext } from '../../../config/themeContext'
import { RootContext } from '../../../config/rootContext'

const HeaderSettingsMenu = ({ className }) => {
  const { theme, setTheme } = useContext(ThemeContext)
  const { autoplayFlag, setAutoplayFlag } = useContext(RootContext)

  return (
    <CircleButton
      className={classnames(className)}
      onClick={() =>
        renderBasicModal({
          html: (
            <Settings
              theme={theme}
              setTheme={setTheme}
              autoplay={autoplayFlag}
              setAutoPlay={setAutoplayFlag}
            />
          ),
        })
      }
    >
      <FontAwesomeIcon
        className={'text-lg text-gray-600 dark:text-gray-200'}
        icon={['fal', 'cog']}
      />
    </CircleButton>
  )
}

HeaderSettingsMenu.propTypes = {
  className: PropTypes.any,
}

export default HeaderSettingsMenu
