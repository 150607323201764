import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useSound from 'use-sound'

// Components
import HtmlContent from '../components/HtmlContent'
import AnswerBoxGroup from '../components/AnswerBoxGroup'
import HeaderSettingsMenu from '../components/Header/HeaderSettingsMenu'

// Utils
import useQuestionAnswers, { questionAnswersWorker } from '../hooks/useQuestionAnswers'
import useQuestionActiveAnswers from '../hooks/useQuestionActiveAnswers'
import questionCorrectMp3 from '../static/success2.mp3'
import questionWrongMp3 from '../static/failure2.mp3'
import Confetti from '../utils/confetti'

const QuestionAppBody = ({
  question,
  answers,
  classNames,
  generator,
  generators,
  reloadQuestion,
  isCorrectCallback,
  isWrongCallback,
  countCorrectAnswers,
  showDarkModeSwitch,
}) => {
  const { t } = useTranslation('apps')

  const { level_name } = generator

  const [playCorrectSound, { stop: stopCorrectSound }] = useSound(questionCorrectMp3)
  const [playWrongSound, { stop: stopWrongSound }] = useSound(questionWrongMp3)

  const {
    shuffledAnswers,
    correctAnswers,
    setRespondedAnswers,
    clearRespondedAnswers,
    wrongAnswers,
    isResponded,
    isWrong,
    isCorrect,
  } = useQuestionAnswers(question.id, answers)

  const [activatedAnswers, activateAnswer, clearActiveAnswers, isActivated] =
    useQuestionActiveAnswers(question.id, correctAnswers.length)

  const getNextGenerator = () => {
    if (countCorrectAnswers === 0) {
      return generators[0] // first easy generator
    }

    const nextGenerator = generators[countCorrectAnswers]

    if (nextGenerator !== undefined) {
      return nextGenerator // next generator is used by step of 1
    }

    return generators[generators.length - 1] // last generator (hardest)
  }

  const onTryAgain = () => {
    clearRespondedAnswers()
    clearActiveAnswers()

    const nextGenerator = getNextGenerator()
    reloadQuestion(nextGenerator.level)
  }

  const onRespondClick = (e) => {
    const { isCorrect: isCorrectActivated, isWrong: isWrongActivated } = questionAnswersWorker(
      activatedAnswers,
      answers,
    )

    if (isCorrectActivated) {
      isCorrectCallback()
      playCorrectSound()
      Confetti.questionCannon(e.target)
    } else if (isWrongActivated) {
      isWrongCallback()
      playWrongSound()
    }

    setRespondedAnswers(activatedAnswers)
  }

  useEffect(() => {
    return () => {
      stopCorrectSound()
      stopWrongSound()
      Confetti.reset()
    }
  }, [stopCorrectSound, stopWrongSound])

  const renderButton = () => {
    if (isResponded === false) {
      return (
        <button
          className={classnames('btn btn-lg w-full', {
            'btn-primary chameleon': isActivated,
            'btn-secondary': isActivated === false,
          })}
          onClick={(e) => onRespondClick(e)}
          disabled={isActivated === false}
        >
          {t('buttons.check-answer')}
        </button>
      )
    }

    return (
      <button
        className={classnames('btn btn-lg w-full', {
          'btn-success': isCorrect === true,
          'btn-danger': isWrong === true,
        })}
        onClick={() => onTryAgain()}
      >
        <FontAwesomeIcon className={'inline-block mr-2 relative'} icon={['fas', 'repeat']} />

        {isCorrect ? t('buttons.try-another') : t('buttons.try-again')}
      </button>
    )
  }

  return (
    <div className={classnames('relative', classNames)}>
      {showDarkModeSwitch === true && (
        <div className={'absolute top-0 right-0 -mr-20'}>
          <HeaderSettingsMenu />
        </div>
      )}

      <HtmlContent id={question.id} katexFontSize={'LARGE'} html={question.content} />

      <AnswerBoxGroup
        classNames={'mb-3'}
        showCorrectAnswer={isResponded}
        isResponded={isResponded}
        isCorrect={isCorrect}
        onClick={(id) => activateAnswer(id)}
        activeAnswers={activatedAnswers}
        wrongAnswers={wrongAnswers}
        correctAnswers={correctAnswers}
        answers={shuffledAnswers}
      />

      {renderButton()}

      <div className={'text-right mt-2 mr-2 dark:text-white'}>
        <span className={'mr-3'}>
          {t('generative.correct-label')} :{' '}
          <span className={'font-medium'}>{countCorrectAnswers}</span>
        </span>
        <span>
          {t('generative.level-label')} : <span className={'font-medium'}>{level_name}</span>
        </span>
      </div>
    </div>
  )
}

QuestionAppBody.propTypes = {
  question: PropTypes.object.isRequired,
  answers: PropTypes.array.isRequired,
  countCorrectAnswers: PropTypes.number.isRequired,

  classNames: PropTypes.any,

  generator: PropTypes.object,
  generators: PropTypes.array,

  reloadQuestion: PropTypes.func.isRequired,
  isCorrectCallback: PropTypes.func.isRequired,
  isWrongCallback: PropTypes.func.isRequired,

  showDarkModeSwitch: PropTypes.bool.isRequired,
}

export default QuestionAppBody
