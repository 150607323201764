import { useIsHowlerUnlock as globaleUseIsHowlerUnlock } from '../state/useHowlerUnlock'
import { Howler } from 'howler'
import useEffectOnce from '../../hooks/useEffectOnce'

const useIsHowlerUnlock = () => {
  const [isUnlocked, setIsUnlocked] = globaleUseIsHowlerUnlock()

  const listenForHowlerUpdate = () => {
    if (isUnlocked === false) {
      waitForUnlock().then(() => {
        setIsUnlocked(true)
      })
    }
  }

  useEffectOnce(listenForHowlerUpdate)

  return isUnlocked
}

export default useIsHowlerUnlock

const howler_listen_timeout = 1000 // ms

const isHowlerAudioUnlocked = () => {
  return Howler._audioUnlocked
}

const waitForUnlock = () => {
  // listening
  return new Promise((resolve) => {
    if (isHowlerAudioUnlocked()) {
      resolve() // if already unlocked
    }

    // checking
    const interval = setInterval(() => {
      if (isHowlerAudioUnlocked()) {
        // unlocked
        clearInterval(interval)
        resolve()
      }
    }, howler_listen_timeout)
  })
}
