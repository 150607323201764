import { clone, shuffle, groupBy } from '../../utils/array'
import { useAllResponded, useAllShuffled } from '../state/useAnswers'
import useEffectOnce from '../../hooks/useEffectOnce'

/**
 * Will get all the necessary info per question
 *
 * @param {*} questionId
 * @param {*} answers
 */
const useQuestionAnswers = (questionId, answers) => {
  const [allRespondedAnswers, updateAllRespondedAnswers] = useAllResponded()
  const [allShuffledAnswers, updateShuffledAnswers] = useAllShuffled()

  const respondedAnswers = allRespondedAnswers[questionId] ? allRespondedAnswers[questionId] : []
  const shuffledAnswers = allShuffledAnswers[questionId]

  const { correctAnswers, wrongAnswers, isResponded, isWrong, isCorrect } = questionAnswersWorker(
    respondedAnswers,
    answers,
  )

  const setRespondedAnswers = (responded) => {
    updateAllRespondedAnswers({
      ...allRespondedAnswers,
      [questionId]: responded,
    })
  }

  const clearRespondedAnswers = () => {
    setRespondedAnswers([])
  }

  const shuffleAnswersLocal = () => {
    const original = clone(answers)
    const shuffledAnswers = shuffle(original)

    updateShuffledAnswers({
      ...allShuffledAnswers,
      [questionId]: shuffledAnswers,
    })
  }

  return {
    shuffledAnswers,
    respondedAnswers,
    setRespondedAnswers,
    clearRespondedAnswers,
    correctAnswers,
    wrongAnswers,
    isResponded,
    isWrong,
    isCorrect,
    shuffleAnswers: shuffleAnswersLocal,
  }
}

export default useQuestionAnswers

/**
 * Will shuffle all the answers
 *
 * @param {*} answers
 */
export const useShuffledAnswersOnce = (answers) => {
  const [, updateShuffledAnswers] = useAllShuffled()

  useEffectOnce(() => {
    updateShuffledAnswers(shuffleAnswers(answers))
  })
}

/**
 * Will shuffle all the answers and save in a global state
 */
export const shuffleAnswers = (answers) => {
  const original = clone(answers)
  const shuffledAnswers = shuffle(original)
  const groupedBy = groupBy(shuffledAnswers, 'question_id')
  return groupedBy
}

/**
 * Is used to calculate if question is answered correctly or not
 */
export const questionAnswersWorker = (qRespondedAnswers, qAnswers) => {
  const correctAnswers = qAnswers.filter((a) => a.correct === true).map((a) => a.id)
  const wrongAnswers = qRespondedAnswers.filter((a) => correctAnswers.indexOf(a) === -1)

  const isResponded =
    qRespondedAnswers.length !== 0 && qRespondedAnswers.length === correctAnswers.length

  const isWrong = isResponded && wrongAnswers.length !== 0

  const isCorrect = isResponded && wrongAnswers.length === 0

  return {
    correctAnswers,
    wrongAnswers,
    isResponded,
    isWrong,
    isCorrect,
  }
}
