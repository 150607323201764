import React, { useEffect, useState, useCallback, useContext } from 'react'
import ReactDOM from 'react-dom'
import classnames from 'classnames'
import Katex from '../../../components/Katex'
import AudibleElement from '../AudibleElement'
import AudioMapList from '../../utils/audio'
import { RootContext } from '../../../config/rootContext'
import useInitial from '../../../hooks/useInitial'

function loadKatex(className, containerId, katexDisplayMode, katexFontSize) {
  const elements =
    containerId !== null
      ? document.getElementById(containerId).getElementsByClassName(className)
      : document.getElementsByClassName(className)

  const katexElements = [].slice.call(elements)

  return katexElements.map((el) => {
    if (el.dataset.katex_loaded !== undefined) {
      return el
    }

    // Html decode value
    let txt = document.createElement('textarea')
    txt.innerHTML = el.innerHTML
    let katexValue = txt.value

    let katexOptionsJson = JSON.parse(el.dataset.katex)

    if (katexDisplayMode !== null) {
      katexOptionsJson['displayMode'] = katexDisplayMode
    }

    if (katexOptionsJson.displayMode === true && katexFontSize) {
      katexValue = '\\' + katexFontSize + ' ' + katexValue
    }

    ReactDOM.render(<Katex math={katexValue} settings={katexOptionsJson} />, el)

    el.dataset.katex_loaded = true

    return el
  })
}

function loadAudibleElements(className, containerId, audibleAutoplayFirst, autoplayFlag) {
  const elements = document.getElementById(containerId).getElementsByClassName(className)

  const audibleElements = [].slice.call(elements)

  // populate audio map
  const audioMap = AudioMapList.getMap(containerId)
  audibleElements.map((el) => audioMap.addAudio(el.dataset.audible, {}, el))

  // render audio elements
  return audibleElements.map((el, index) => {
    if (el.dataset.audible_loaded !== undefined) {
      return el
    }

    const audioName = el.dataset.audible

    const AudibleElementComponent = (
      <AudibleElement
        audioMapID={audioMap.id}
        autoplay={index === 0 && audibleAutoplayFirst === true && autoplayFlag === true}
        audioID={audioName}
        html={el.innerHTML}
      />
    )

    ReactDOM.render(AudibleElementComponent, el)

    el.dataset.audible_loaded = true

    return el
  })
}

const HtmlContent = (props) => {
  const {
    id,
    html,
    children,
    katexDisplayMode,
    katexFontSize,
    audibleAutoplayFirst,
    proseFullWidth,
  } = props

  const containerId = 'content-' + id

  const [loadedId, setLoadedId] = useState(null)

  const { autoplayFlag } = useContext(RootContext)
  const initialAutoPlayFlag = useInitial(autoplayFlag) // prevent rerendering on config change

  const loadKatexCallback = useCallback(
    () =>
      new Promise((resolve) =>
        resolve(loadKatex('academator-katex', containerId, katexDisplayMode, katexFontSize)),
      ),
    [containerId, katexDisplayMode, katexFontSize],
  )

  const loadAudibleCallback = useCallback(
    () =>
      new Promise((resolve) =>
        resolve(
          loadAudibleElements(
            'academator-audible',
            containerId,
            audibleAutoplayFirst,
            initialAutoPlayFlag,
          ),
        ),
      ),
    [containerId, audibleAutoplayFirst, initialAutoPlayFlag],
  )

  useEffect(() => {
    let audibles,
      katexes = []

    loadAudibleCallback()
      .then((elements) => {
        audibles = elements
        return loadKatexCallback()
      })
      .then((elements) => {
        katexes = elements
      })
      .finally(() => {
        setLoadedId(id)
      })

    return () => {
      // unmount components created on the fly
      audibles.map((el) => ReactDOM.unmountComponentAtNode(el))
      katexes.map((el) => ReactDOM.unmountComponentAtNode(el))
    }
  }, [id, loadKatexCallback, loadAudibleCallback])

  const isLoading = loadedId !== id

  const classNames = classnames('prose dark:prose-dark mx-auto', {
    'prose-sm sm:prose-sm': true,
    'md:prose': true,
    'lg:prose-lg': true,
    'xl:prose-xl': true,
    '2xl:prose-2xl': true,
    'w-full max-w-none md:max-w-none': proseFullWidth,
    hidden: isLoading === true,
    block: isLoading === false,
  })

  if (html) {
    return (
      <div id={containerId} className={classNames} dangerouslySetInnerHTML={{ __html: html }} />
    )
  }

  return (
    <div id={containerId} className={classNames}>
      {children}
    </div>
  )
}

HtmlContent.defaultProps = {
  katexDisplayMode: null, // is set by backend. override if necessary
  katexFontSize: null, // is set by backend. override if necessary
  audibleAutoplayFirst: false, // autoplay first audible
  proseFullWidth: false, // used for questions to align with answer box group
}

export default HtmlContent
