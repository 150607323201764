import React from 'react'
import useEffectOnce from './useEffectOnce'

/**
 * Hook for getting initial value
 *
 * @param value
 * @returns {*}
 */
function useInitial(value) {
  const ref = React.useRef(value)

  const init = () => {
    ref.current = value
  }

  useEffectOnce(init)

  return ref.current
}

export default useInitial
