import { useState } from 'react'

const useQuestionActiveAnswers = (questionId, maxActiveAnswers) => {
  const [allActiveAnswers, setAllActiveAnswers] = useState({})

  const getActiveAnswers = () => {
    if (allActiveAnswers[questionId] !== undefined) {
      return allActiveAnswers[questionId]
    }

    return []
  }

  const setActiveAnswers = (answers) => {
    setAllActiveAnswers({
      ...allActiveAnswers,
      [questionId]: answers,
    })
  }

  const activateAnswer = (id) => {
    const uniqueNewActive = [...new Set([...getActiveAnswers(), id])]

    if (uniqueNewActive.length > maxActiveAnswers) {
      uniqueNewActive.shift()
    }

    setActiveAnswers(uniqueNewActive)
  }

  const clearActiveAnswers = () => {
    setAllActiveAnswers([])
  }

  const isActivated = () => {
    return getActiveAnswers().length >= maxActiveAnswers
  }

  return [getActiveAnswers(), activateAnswer, clearActiveAnswers, isActivated()]
}

export default useQuestionActiveAnswers
