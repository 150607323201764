import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import DarkModeButton from '../../../components/Button/DarkModeButton'
import SwitchButton from '../../../components/Button/SwitchButton'

const Settings = ({ theme, setTheme, autoplay, setAutoPlay }) => {
  const { t } = useTranslation('apps')

  return (
    <div className={'flex flex-col dark:text-white px-3 pt-4 pb-3'}>
      <h2 className={'text-2xl mb-6 font-bold'}>{t('modal.settings.header')}</h2>

      <div>
        <table className={'w-full'} style={{ borderSpacing: '20px', borderCollapse: 'separate' }}>
          <tbody>
            <tr>
              <th>{t('modal.settings.labels.dark-mode')}</th>

              <td>
                <DarkModeButton theme={theme} setTheme={setTheme} />
              </td>
            </tr>

            <tr>
              <th>{t('modal.settings.labels.autoplay-voice')}</th>

              <td>
                <SwitchButton value={autoplay} setValue={setAutoPlay} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

Settings.propTypes = {
  theme: PropTypes.string,
  setTheme: PropTypes.func,
  autoplay: PropTypes.bool,
  setAutoPlay: PropTypes.func,
}

export default Settings
